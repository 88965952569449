import { flatMap } from 'lodash';

const IMAGES_BASE = ['image/jpg', 'image/jpeg', 'image/png'];
const ACCEPTED_FILES_BY_CATEGORY = {
  images: IMAGES_BASE,
  images_ai: IMAGES_BASE.concat(['image/x-adobe-dng']),
  images_ai_mixer: IMAGES_BASE.concat(['image/x-adobe-dng', 'image/heic', 'image/heif']),
  videos: ['video/mp4', 'video/webm', 'video/quicktime'],
  audios: ['audio/mp4', 'audio/mp3', 'audio/mpeg', 'audio/wav', 'audio/x-m4a', 'audio/aac'],
  images_dreamscale: ['image/jpeg', 'image/png', 'image/webp'],
  assistants: IMAGES_BASE.concat([
    'image/gif',
    'application/pdf',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ]),
  documents: ['application/pdf','text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  others: [
    'image/gif',
    'image/tiff',
    'audio/aac',
    'audio/x-m4a',
    'video/x-msvideo',
    'text/csv',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'audio/mpeg',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/rtf',
    'application/x-rtf',
    'text/rtf',
    'text/richtext',
    'text/plain',
    'audio/wav',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/vnd.microsoft.icon',
    'image/svg+xml',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'video/x-ms-wmv'
  ]
};

const ACCEPTED_CATEGORIES_BY_FEATURE = {
  casting_call_cover: ['images'],
  casting_call_attachments: ['images', 'videos', 'others'],
  listings_samples: ['images', 'videos'],
  pitch_video: ['videos'],
  pitch_attachments: ['images', 'videos', 'others'],
  order_content_deliver: ['images', 'videos', 'others'],
  order_file_upload: ['images'],
  user_cover: ['images'],
  ai_assistant: ['assistants'],
  assistant_persona: ['documents'],
  ai_image: ['images_ai'],
  ai_init_audio: ['videos', 'others'],
  ai_mixer_image: ['images_ai_mixer'],
  ai_audio: ['audios'],
  ai_dreamscale: ['images_dreamscale'],
  ai_remove_background: ['images'],
  ai_replace_background: ['images'],
  ai_voice_training: ['audios'],
  ai_talking_portrait: ['images']
};

export function getAcceptedFilesFromFeature(feature) {
  let acceptedCategories = ACCEPTED_CATEGORIES_BY_FEATURE[feature];
  if (!acceptedCategories) {
    throw Error(`AcceptedFiles: Unknown feature: ${feature}`);
  }

  let acceptedFiles = flatMap(acceptedCategories, function (category) {
    let types = ACCEPTED_FILES_BY_CATEGORY[category];
    if (!types) {
      throw Error(`AcceptedFiles: Unknown category: ${category}`);
    }
    return types;
  });

  return acceptedFiles.join(',');
}
