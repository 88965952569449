import { emit } from './eventbus';
import { DirectUpload } from '@rails/activestorage';
import {post} from '@rails/request.js';
// import {formatBytes} from './index';
// import { isNil } from 'lodash';

// TODO document the flow
// TODO document how to use

class UploadManager {
  // constructor(options) {
  constructor() {
    this.uploadsByTemporaryId = new Map();
    this.uploadsCompleteCallback = null;
  }

  get countPreBlobUploads(){
    return Array.from(this.uploadsByTemporaryId.values()).filter(upload => upload.isPreBlob).length;
  }

  get countUploading(){
    return this.uploadsUploading.length;
  }

  get uploadsUploading(){
    return Array.from(this.uploadsByTemporaryId.values()).filter(upload => !upload.uploaded);
  }

  get totalPercentageUploaded(){
    let uploadsUploading = this.uploadsUploading;
    if(uploadsUploading.length === 0){
      // If anything uploaded, we're at 100%; if we haven't started, we're at 0
      return this.uploadsByTemporaryId.size > 0 ? 100 : 0;
    }
    return uploadsUploading.reduce((acc, upload) => acc + upload.progress, 0) / uploadsUploading.length;
  }

  startUpload(file, options = {}) {
    this.uploadsCompleteCallback = options.uploadsCompleteCallback;

    // Important to be unique, so when we get TurboStream updates, we only update the correct one, not a different one in a different tab
    const upload = new Upload(file, Object.assign(options, {manager: this}));
    this.uploadsByTemporaryId.set(upload.temporaryId, upload);
    upload.start();
    return upload;
  }

  // DirectUpload learns this upon completion of the upload on its own, but if we can learn it earlier externally, we can include it in events earlier (otherwise just temporaryId)
  onLearnedKeyForTemporaryId(temporaryId, key) {
    let upload = this.uploadsByTemporaryId.get(temporaryId);
    upload.setKey(key);
    return upload;
  }

  onUploadComplete(upload) {
    if(this.countUploading > 0) return;
    this.onAllUploadsComplete(upload);
  }

  async onAllUploadsComplete(upload){
    if(!this.uploadsCompleteCallback) return;

    let body = Object.assign({}, upload.context, {signed_id: upload.signedId});
    body.signed_ids = Array.from(this.uploadsByTemporaryId.values()).map(upload => upload.signedId);

    let isJsonMode = this.uploadsCompleteCallback.mode === 'json';
    let response = await post(this.uploadsCompleteCallback.url, {
      body,
      responseKind: isJsonMode ? 'json' : 'turbo-stream'
    });

    if(isJsonMode){
      let json = await response.json;
      if(json.redirect_path){
        window.location.href = json.redirect_path;
      }
    }
  }

}

class Upload {
  constructor(file, options) {
    this.status = 'initializing'; // initializing, preProcessing, preparing (getting signed url url), uploading, postProcessing, done, error
    this.temporaryId = `turbo-uploader-file-tmp-id-${crypto.randomUUID()}`;
    this.file = file;
    this.options = options || {};
    this.context = this.options.context || {};
    this.manager = this.options.manager;
    this.key = null; // Known only after Blob is created in Rails
    this.signedId = null; // Known only after Blob is created in Rails
    this.progress = 0;
    this.uploaded = false;
    this.uploadCompleteCallback = this.options.uploadCompleteCallback ?? null;

    if(!this.directUploadUrl){
      throw new Error('options.directUploadUrl is required');
    }
  }

  get isPreBlob(){
    return !this.key;
  }

  get directUploadUrl(){
    return `${this.options.directUploadUrl}&temporary_id=${this.temporaryId}&context_type=${this.context.type}`;
  }

  setKey(key){
    this.key = key;
  }

  start() {
    if(this.status !== 'initializing') return;

    this.directUploadController = new DirectUploadController(this);
    this.directUploadController.start();
  }

  onUploadingBegan(request){
    request.upload.addEventListener('progress', (event) => this.onUploadProgress(event));
  }

  onUploadProgress(event){
    this.progress = Math.floor((event.loaded / event.total) * 100);
    this.emitEvent('turboUpload:uploadProgress', { progress: this.progress, event });
  }

  async onUploadComplete(blob){
    this.signedId = blob.signed_id;
    this.key = blob.key;
    this.uploaded = true;
    this.emitEvent('turboUpload:uploadComplete', { blob });

    // Backend-render the new template for the File, but can also be a custom callback to do more
    await post(this.uploadCompleteCallback || `/turbo_upload/${this.signedId}/after_uploaded`, {
      body: {
        blob_signed_id: this.signedId,
        context: this.context,
      },
      responseKind: 'turbo-stream'
    });

    this.manager.onUploadComplete(this);
  }

  emitEvent(eventName, data = {}){
    emit(eventName, { upload: this, key: this.key, ...data });
  }

}

// Needed to upload to Rails ActiveStorage; Each Upload has 1 DirectUpload and 1 DirectUploadController (by necessity)
class DirectUploadController {
  constructor(upload) {
    this.upload = upload;
    this.directUpload = new DirectUpload(upload.file, upload.directUploadUrl, this);
  }

  start() {
    this.directUpload.create((error, blob) => {
      if (error) {
        throw new Error(error);
      } else {
        this.upload.onUploadComplete(blob);
      }
    });
  }

  // Called by DirectUpload
  directUploadWillStoreFileWithXHR(request) {
    this.upload.onUploadingBegan(request);
  }
}

// Gets a single Global instance
let uploadManager;
function getUploadManager() {
  if (!uploadManager) {
    uploadManager = new UploadManager();
  }
  return uploadManager;
}

export { UploadManager, getUploadManager };
